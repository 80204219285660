<template>
  <div>
    <sub-header :backButton="true"></sub-header>

    <!-- order detail forms -->

    <div class="grid gap-7 mt-2">
      <!-- Order details -->
      <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Order Details
        </div>
        <div
          class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
          v-if="loadingState.packDetails"
        >
          <Loader />
        </div>
        <FormulateForm v-else>
          <div class="grid grid-cols-3 gap-x-6 p-2 ml-2">
            <div class="flex w-full">
              <!-- Client Name -->
              <div class="w-11/12">
                <FormulateInput
                  class="w-full disableInput"
                  label="Client Name"
                  placeholder="Client name"
                  input-class="cursor-not-allowed"
                  type="text"
                  disabled
                  v-model="orderDetails.client_name"
                  :element-class="
                    (context, classes) => ['flex-1 min-w-full'].concat(classes)
                  "
                />
              </div>
              <!-- Currency code (symbol) -->
              <div class="flex w-1/12 items-center justify-end mt-1">
                <div
                  class="flex h-11 bg-gray-100 w-3/4 rounded-lg items-center justify-center"
                >
                  <div class="">{{ currencySymbol }}</div>
                </div>
              </div>
            </div>

            <FormulateInput
              class="w-full disableInput"
              label="Order Id"
              placeholder="order id"
              type="text"
              input-class="cursor-not-allowed"
              v-model="orderDetails.order_number"
              disabled
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
            />
            <FormulateInput
              class="w-full"
              :class="{'disableInput' : disableMethod}"
              label="Tax Number (VAT, GSTIN etc..)"
              placeholder="tax number"
              type="text"
              :disabled="disableMethod"
              v-model="orderDetails.tax_number"
              :element-class="
                (context, classes) => ['flex-1 min-w-full'].concat(classes)
              "
            />
            <FormulateInput
              class="w-full disableInput"
              label="Order Date"
              placeholder="date"
              type="date"
              disabled
              input-class="cursor-not-allowed"
              :value="orderDetails.date"
              :element-class="
                (context, classes) => ['flex-1 min-w-full '].concat(classes)
              "
            />
            <div>
              <h2 class="mb-2 mt-1">Payment Term</h2>
              <Dropdown
                :disabled="disableMethod"
                :class="{'disableDropdown' : disableMethod}"
                placeholder="Payment Terms"
                :options="paymentTerms"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                :value="orderDetails.payment_term.name"
                v-model="orderDetails.payment_term"
                :config="{ label: 'name', trackBy: 'name' }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
              />
            </div>
            <div>
              <!-- <h2 class="mb-2 mt-1">Order Reference</h2>
              <Dropdown
                :disabled="disableMethod"
                placeholder="Order reference"
                :options="orderReferences"
                :colors="{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                }"
                v-model="orderDetails.order_reference"
                :config="{ label: 'name', trackBy: 'name' }"
                maxWidth="100%"
                minWidth="100%"
                width="102px"
              /> -->
              <FormulateInput
                class="w-full disableInput"
                label="Order reference"
                placeholder="Order reference"
                input-class="cursor-not-allowed"
                type="text"
                disabled
                v-model="orderDetails.order_reference"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
              />
            </div>
          </div>
        </FormulateForm>
      </div>
      <!-- Order details ends -->

      <!-- Pack Details -->
      <!-- v-if="orderDetails.tenant_name" -->
      <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Pack Details
          <svg
            v-if="creditPacksList.length > 0 && !disableMethod"
            @click="addPack"
            class="cursor-pointer"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1758_9116)">
              <path
                d="M3.41391 8.69432C3.03391 8.31088 3.03391 7.68963 3.41391 7.3065C3.59641 7.12275 3.84391 7.01931 4.10172 7.01931H11.8892C12.4267 7.00994 12.8698 7.4415 12.8792 7.98338C12.8886 8.52525 12.4605 8.97213 11.923 8.9815C11.9117 8.9815 11.9005 8.9815 11.8892 8.9815H4.10203C3.84391 8.98244 3.59609 8.879 3.41391 8.69432Z"
                fill="#0D69D5"
              />
              <path
                d="M7.31921 12.6994C7.13921 12.5125 7.03827 12.2584 7.03921 11.9937V4.00717C7.03014 3.45592 7.45077 3.00154 7.97921 2.99186C8.50764 2.98217 8.94327 3.42123 8.95264 3.97248C8.95264 3.98404 8.95264 3.99561 8.95264 4.00717V11.9937C8.95264 12.545 8.52421 12.9919 7.99577 12.9915C7.74202 12.9915 7.49889 12.8865 7.31952 12.6994H7.31921Z"
                fill="#0D69D5"
              />
              <path
                d="M11.614 16H10.5625C10.1693 16 9.85059 15.6812 9.85059 15.2881C9.85059 14.895 10.1693 14.5763 10.5625 14.5763H11.614C12.4012 14.5763 13.144 14.2669 13.7056 13.7053C14.2671 13.1438 14.5765 12.4009 14.5765 11.6138V4.38594C14.5765 3.59875 14.2671 2.85594 13.7056 2.29437C13.144 1.73281 12.4012 1.42344 11.614 1.42344H10.5625C10.1693 1.42344 9.85059 1.10469 9.85059 0.711563C9.85059 0.318438 10.169 0 10.5621 0H11.6137C14.0321 0 15.9996 1.9675 15.9996 4.38594V11.6138C15.9996 14.0322 14.0321 15.9997 11.6137 15.9997L11.614 16Z"
                fill="#0D69D5"
              />
              <path
                d="M5.43781 16H4.38625C1.9675 16 0 14.0325 0 11.6141V4.38594C0 1.9675 1.9675 0 4.38594 0H5.4375C5.83063 0 6.14937 0.31875 6.14937 0.711875C6.14937 1.105 5.83063 1.42375 5.4375 1.42375H4.38594C3.59875 1.42375 2.85594 1.73312 2.29437 2.29469C1.73281 2.85625 1.42344 3.59906 1.42344 4.38625V11.6141C1.42344 12.4012 1.73281 13.1441 2.29437 13.7056C2.85594 14.2672 3.59875 14.5766 4.38594 14.5766H5.4375C5.83063 14.5766 6.14937 14.8953 6.14937 15.2884C6.14937 15.6816 5.83063 16.0003 5.4375 16.0003L5.43781 16Z"
                fill="#0D69D5"
              />
            </g>
            <defs>
              <clipPath id="clip0_1758_9116">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
          v-if="loadingState.packDetails"
        >
          <Loader />
        </div>
        <div>
          <custom-table
            :columns="columns"
            :rows="rows"
            class="p-2"
            :showSubTotal="showSubTotal"
            :subTotalFields="subTotalFields"
            :customClass="customClass"
            :totalAmount="totalAmount"
            :currencySymbol="currencySymbol"
            @noteEditor="onEditorChange"
            userType="client_user"
            emptyTableMessage="No credit pack alloted to tenant."
            :notes="orderDetails.note"
            :orderTable="true"
            :disabledNote="editMode"
          >
            <template v-slot:pack_name="props">
              <div class="justify-center ml-3">
                <Dropdown
                  :disabled="disableMethod"
                  :class="{'disableDropdown' : disableMethod}"
                  placeholder="Credit Packs"
                  :options="creditPacksList"
                  searchable
                  reposition
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  v-model="props.item.name"
                  @input="handleDiscount(props.item)"
                  :config="{ label: 'name', trackBy: 'name' }"
                  maxWidth="93%"
                  minWidth="93%"
                  width="200px"
                />
              </div>
            </template>
            <!-- <template v-slot:available_packs="props">
              <div class="justify-center">
                <input
                  disabled
                  type="text"
                  class="input input-bordered input-sm w-1/2"
                  v-model="props.item.available_packs"
                />
              </div>
            </template> -->
            <template v-slot:quantity="props">
              <div class="justify-center">
                <input
                  :disabled="disableMethod"
                  type="number"
                  class="input input-bordered input-sm w-1/2 removeArrowKey"
                  v-model="props.item.quantity"
                  @input="handleDiscountedPrice(props.item)"
                  @keypress="isIntegerNumber"
                />
              </div>
            </template>
            <template v-slot:unit_price="props">
              <label class="input-group input-group-xs flex justify-center">
                <span>{{ currencySymbol }}</span>
                <input
                  type="text"
                  class="input input-bordered input-sm w-1/2"
                  v-model="props.item.unit_price"
                  disabled
                />
              </label>
            </template>
            <template v-slot:discount="props">
              <label class="input-group input-group-sm flex justify-center">
                <span>%</span>
                <input
                  disabled
                  type="text"
                  class="input input-bordered input-sm w-1/2"
                  v-model="props.item.discount"
                  @input="handleDiscountedPrice(props.item)"
                />
              </label>
            </template>
            <template v-slot:discounted_price="props">
              <label class="input-group input-group-sm flex justify-center">
                <span>{{ currencySymbol }}</span>
                <input
                  type="text"
                  class="input input-bordered input-sm w-1/2"
                  v-model="props.item.discounted_price"
                  disabled
                />
              </label>
            </template>
            <template v-slot:action="props">
              <svg
                @click="removeRow(props.item)"
                class="justify-center cursor-pointer ml-16"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="!disableMethod"
              >
                <path
                  d="M3.62645 4.87203V13.2127C3.62645 14.1998 4.48363 15 5.54033 15H11.2824C12.3395 15 13.1967 14.1998 13.1967 13.2127V4.87203H3.62645ZM6.18072 13.1217C6.18072 13.3385 6.03751 13.514 5.86074 13.514H5.54034C5.36336 13.514 5.22016 13.3385 5.22016 13.1217L5.22016 6.4518C5.22016 6.24277 5.35451 6.07583 5.52225 6.06362H5.87823C6.04597 6.07583 6.18032 6.24278 6.18032 6.4518V13.1217H6.18072V13.1217ZM9.05172 13.1217C9.05172 13.3385 8.90853 13.514 8.73173 13.514H8.09117C7.9146 13.514 7.77119 13.3385 7.77119 13.1217V6.4518C7.77119 6.24277 7.90557 6.07583 8.07328 6.06362H8.74926C8.91721 6.07583 9.05134 6.24278 9.05134 6.4518V13.1217H9.05172V13.1217ZM11.6028 6.4518V13.1218C11.6028 13.3387 11.4596 13.5141 11.2824 13.5141H10.9622C10.7856 13.5141 10.6424 13.3387 10.6424 13.1218V6.4518C10.6424 6.24277 10.7766 6.07583 10.9445 6.06362H11.3003C11.4686 6.07583 11.6028 6.24277 11.6028 6.4518ZM13.1967 2.48925H3.62645C3.27408 2.48925 2.98828 2.75611 2.98828 3.08494V3.38279C2.98828 3.71199 3.27408 3.9785 3.62645 3.9785H13.1967C13.5488 3.9785 13.8346 3.71199 13.8346 3.38279V3.08494C13.8346 2.75611 13.5492 2.48925 13.1967 2.48925Z"
                  fill="#FF0000"
                />
                <path
                  d="M7.65891 1H9.27774C9.63032 1 9.91572 1.26687 9.91572 1.5957V1.89354C9.91572 2.22275 9.62992 2.48925 9.27774 2.48925H7.65891C7.30655 2.48925 7.02075 2.22275 7.02075 1.89354V1.5957C7.02075 1.26687 7.30655 1 7.65891 1Z"
                  fill="#FF0000"
                />
              </svg>
            </template>
          </custom-table>
        </div>
      </div>
      <!-- Pack Details ends -->
    </div>
    <!-- Order detail form ends -->
    <div
      class="flex gap-3 justify-end"
      v-if="
        (orderDetails?.status == 'draft' || !orderDetails?.status) &&
        !orderStatusDone
      "
    >
      <div class="flex">
        <Button
          :loader="loadingState.draftOrder"
          text="Save in Draft"
          class="mt-2"
          type="primary"
          @click="createOrders(true)"
          :disabled="rows.length > 0 ? false : true"
        />
      </div>

      <div class="flex">
        <Button
          :loader="loadingState.submitOrder"
          text="Submit Order"
          class="mt-2"
          type="primary"
          @click="createOrders(false)"
          :disabled="rows.length > 0 ? false : true"
        />
      </div>

      <div class="flex">
        <Button
          text="Cancel"
          class="mt-2"
          type="primary"
          @click="cancelOrder"
          :disabled="rows.length > 0 ? false : true"
        />
      </div>
    </div>

    <!-- Cancel Order popup  -->

    <modal-confirm
      title="Are you sure?"
      message="Are you sure you want to cancel your Order"
      ref="confirm-popup"
      submitButtonText="Yes"
      additionalBtn="No"
    ></modal-confirm>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import customTable from "@shared/components/custom-table";
import Dropdown from "@shared/components/dropdown-base";
import Loader from "@shared/loader";
const modalConfirm = () => import("@/components/modal-confirm");
import { isIntegerNumber } from "../../../plugins/functions.js";

import {
  createOrder,
  getOrderId,
  getOrderDetails,
  getOrderCreditPacks,
  updateOrder,
  getClientConfig,
  getClientTax,
  getClientCreditPackList,
} from "../services";
import Button from "@shared/components/button";
export default {
  name: "createOrders",
  components: {
    SubHeader,
    customTable,
    Dropdown,
    Button,
    Loader,
    modalConfirm,
  },
  data: () => {
    return {
      orderStatusDone: false,
      paymentTerms: [
        { name: "Due on receipt", value: "due on receipt" },
        { name: "Due 15 days", value: "due 15 days" },
        { name: "Due 30 days", value: "due 30 days" },
        { name: "Due 45 days", value: "due 45 days" },
        { name: "Due 60 days", value: "due 60 days" },
      ],
      orderReferences: [{ name: "Internal" }, { name: "External" }],
      showSubTotal: false,
      loadingState: {
        packDetails: false,
        submitOrder: false,
        draftOrder: false,
        approveOrder: false,
        rejectOrder: false,
      },
      columns: [
        {
          name: "Pack Name",
          label: "pack_name",
          type: "custom",
        },
        // {
        //   name: "Available",
        //   label: "available_packs",
        //   type: "custom",
        // },
        {
          name: "Quantity",
          label: "quantity",
          type: "custom",
        },
        {
          name: "Unit Price",
          label: "unit_price",
          type: "custom",
        },
        {
          name: "Discount",
          label: "discount",
          type: "custom",
        },
        {
          name: "Discounted Price",
          label: "discounted_price",
          type: "custom",
        },
        {
          name: "Action",
          label: "action",
          type: "custom",
        },
      ],
      rows: [],
      subTotalFields: [
        { name: "SUB TOTAL", percent: "-", value: 0 },
        // { name: "Corporate Discount", percent: 5, value: 0 },
      ],
      orderDetails: {
        client_name: null,
        order_number: null,
        tax_number: null,
        order_reference: "External",
        payment_term: { name: "Due 30 days", value: "due 30 days" },
        created_at: null,
        date: null,
      },
      creditPacksList: [],
      customClass: {
        headers: "px-11",
      },
      totalAmount: null,
      subTotal: null,
      orderId: null,
      clientId: null,
      status: "",
      selectedPacks: [],
      currencySymbol: "",
      clientTaxes: [],
      corpDiscount: 0,
    };
  },
  computed: {
    disableMethod() {
      if (this.orderDetails?.status == "approved") {
        return true;
      } else if (this.orderDetails?.status == "rejected") {
        return true;
      } else if (this.orderDetails?.status == "pending approval") {
        return true;
      } else {
        return false;
      }
    },
    disableFields() {
      if (this.orderDetails?.status == "draft") {
        return true;
      } else {
        return false;
      }
    },
    editMode() {
      return this.$route.params.id ? true: false;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.handleOrderDetails();
    } else {
      await this.handleCreateOrder();
    }
  },

  methods: {
    isIntegerNumber,
    onEditorChange(value) {
      this.orderDetails.note = value;
    },
    async handleOrderDetails() {
      this.loadingState.packDetails = true;
      this.setClientConfigToDetail();
      const orderDetails = await getOrderDetails(this.$route.params.id);
      const orderPackDetails = await getOrderCreditPacks(this.$route.params.id);
      const creditResponse = await getClientCreditPackList();
      creditResponse.data.forEach((res) => {
        this.creditPacksList.push({
          name: res.name,
          amount: res.unit_price,
          discount: res.discount,
        });
      });
      this.corpDiscount = this.orderDetails.discount;

      this.orderDetails = {
        client_name: this.$route.params.client_name,
        date: new Date(orderDetails.data.created_at)
          .toISOString()
          .substring(0, 10),
        ...orderDetails.data,
      };

      // orderPackDetails.data.forEach((res) => {
      //   this.creditPacksList.push({
      //     name: res.name,
      //     amount: res.unit_price,
      //     id: res.id,
      //     currency_id: res.currency_id,
      //   });
      // });

      this.rows = orderPackDetails.data;
      this.handleTotal();
      this.calculateSubTotal();
      if (this.rows.length > 0) {
        this.showSubTotal = true;
      } else {
        this.showSubTotal = false;
      }
      this.loadingState.packDetails = false;
    },
    async handleCreateOrder() {
      try {
        this.loadingState.packDetails = true;
        this.setClientConfigToDetail();

        const response = await getOrderId();

        this.orderId = response?.data?.order_id;
        this.orderDetails.order_number = response?.data?.order_number;
        this.orderDetails.date = new Date().toISOString().substring(0, 10);

        const creditResponse = await getClientCreditPackList();

        creditResponse.data.forEach((res) => {
          this.creditPacksList.push({
            name: res.name,
            amount: res.unit_price,
            id: res.id,
            currency_id: res.currency_id,
            discount: res.discount,
            discounted_price: res.discounted_price,
            available_packs: res.available_packs,
          });
        });

        if (this.creditPacksList.length > 0) {
          this.addPack();
        }

        await this.calculateSubTotal();

        if (this.rows.length > 0) {
          this.showSubTotal = true;
        } else {
          this.showSubTotal = false;
        }
        this.loadingState.packDetails = false;
      } catch (err) {
        console.error(err);
        this.loadingState.packDetails = false;
      }
    },
    async handleDiscount(value) {
      value.unit_price = value.name.amount;
      value.discount = +value.name.discount;
      value.id = value.name.id;
      value.currency_id = value.name.currency_id;
      value.available_packs = value.name.available_packs;
      value.name = value.name.name;
      this.handleDiscountedPrice(value);
    },
    handleDiscountedPrice(value) {
      const discount = this.convertIntoTwoDecimal(
        (value.unit_price * value.discount) / 100
      );
      value.discounted_price = this.convertIntoTwoDecimal(
        (value.unit_price - discount) * value.quantity
      );
      this.discountCalculation();
    },
    handleTotal() {
      this.discountCalculation();
    },
    async calculateSubTotal() {
      this.subTotalFields = [
        { name: "SUB TOTAL", percent: "-", value: 0 },
        // { name: "Corporate Discount", percent: 0, value: 0 },
      ];
      this.clientTaxes = [];
      const { data } = await getClientTax(this.clientId);
      if (data) {
        data.forEach((res) => {
          this.clientTaxes.push({
            name: res.tax_name,
            rate: res.rate,
          });
          this.subTotalFields.push({
            name: res.tax_name,
            percent: res.rate,
            value: 0,
          });
        });
      }
      this.discountCalculation();
    },
    addPack() {
      this.rows.push({
        row_id: this.rows.length + 1,
        name: "",
        available_packs: 0,
        quantity: 1,
        unit_price: 0,
        discount: 0,
        discounted_price: 0,
        action: true,
        id: "",
      });
    },
    removeRow(value) {
      this.rows = this.rows.filter((res) => res.row_id !== value.row_id);
      this.handleTotal();
    },
    async createOrders(drafted) {
      try {
        let creditPackSelected = true;
        let creditPackDuplicated = false;
        let invalidQuantity = false;
        let selectedPacks = [];
        this.rows.forEach((res) => {
          if (selectedPacks.includes(res.name)) {
            creditPackDuplicated = true;
          }
          selectedPacks.push(res.name);
          if (!res.name) {
            creditPackSelected = false;
          }
          // if (parseInt(res.quantity) > parseInt(res.available_packs)) {
          //   invalidQuantity = true;
          // }
          if (res.quantity <= 0) {
            invalidQuantity = true;
          }
        });
        // if (invalidQuantity) {
        //   this.$toast.error('Quantity can not be grater then available packs.');
        //   return;
        // }
        // this.orderDetails.client_name = config?.data?.name;
        //   this.orderDetails.tax_number
        if(!this.orderDetails.client_name) {
          this.$toast.error("Client name is required.");
          return;
        }
        // if(!this.orderDetails.tax_number) {
        //   this.$toast.error("Tax number is required.");
        //   return;
        // }
        if (!creditPackSelected) {
          this.$toast.error("Please select credit pack.");
          return;
        }
        if (creditPackDuplicated) {
          this.$toast.error("Credit Packs cannot be duplicated.");
          return;
        }
        if (!this.orderDetails?.payment_term) {
          this.$toast.error("Please select the payment terms.");
          return;
        }
        if (invalidQuantity) {
          this.$toast.error("Quantity should be greater then 0.");
          return;
        }

        if (drafted) {
          this.loadingState.draftOrder = true;
        } else {
          this.loadingState.submitOrder = true;
        }
        let tax = [];
        this.clientTaxes.forEach((res) => {
          tax.push({
            name: res.name,
            rate: res.rate,
          });
        });

        this.rows.forEach((row) => {
          delete row.row_id;
          delete row.action;
        });

        let payload = {
          order_reference: this.orderDetails.order_reference,
          payment_term:
            this.orderDetails?.payment_term?.value ||
            this.orderDetails?.payment_term ||
            "",
          sub_total: this.subTotal,
          discount: this.corpDiscount || 0.0,
          taxes: tax,
          draft: drafted,
          note: this.orderDetails.note ? this.orderDetails.note : "",
          order_number: this.orderDetails.order_number,
          order_id: this.orderId,
          credit_packs: this.rows,
          total_amount: parseFloat(this.totalAmount),
          client_id: this.clientId,
        };
        if (this.$route.params.id) {
          (payload.order_id = this.$route.params.id),
            (payload.client_id = this.clientId);
          await updateOrder(payload, this.$route.params.id);
        } else {
          await createOrder(payload);
        }
        this.$toast.success(
          drafted
            ? "Order drafted successfully"
            : "Order submitted successfully."
        );
        this.loadingState.submitOrder = false;
        this.loadingState.draftOrder = false;
        this.$router.push({ name: "purchaseOrderList" });
      } catch (err) {
        console.error(err);
        this.loadingState.submitOrder = false;
        this.loadingState.draftOrder = false;
        if (err?.response.status == 400) {
          this.$toast.error(err.response.data.detail);
        } else {
          this.$toast.error("Some error occured");
        }
      }
    },
    async cancelOrder() {
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message: "Are you sure you want to cancel your Order",
      });
      if (promise) {
        this.$router.push({ name: "purchaseOrderList" });
      }
      this.$refs["confirm-popup"].close();
    },
    async setClientConfigToDetail() {
      if (!this.$store.getters.getClientId) {
        await this.$store.dispatch("fetchClientId");
      }
      this.clientId = await this.$store.getters.getClientId;
      if (this.clientId && this.clientId !== null) {
        try {
          const config = await getClientConfig(this.clientId);
          this.orderDetails.client_name = config?.data?.name;
          this.orderDetails.tax_number = config.data.tax_number;
          this.currencySymbol = config.data.currency_symbol;
        } catch (err) {
          if(err.status === undefined){
            this.$toast.error('Internal server error')
          }
          else {
            this.$toast.error(err.message);
          }
          console.error(err);
        }
      }
    },
    // calculation the total amount and sub total field amount
    discountCalculation() {
      let subTotal = 0;
      let discounts = 0;
      this.subTotal = 0;
      let totalTaxPercentage = [];

      this.rows.forEach((row) => {
        subTotal += parseFloat(row.discounted_price);
      });

      this.subTotal = subTotal;

      this.subTotalFields.forEach((res) => {
        if (res.name == "SUB TOTAL") {
          res.value = this.convertIntoTwoDecimal(subTotal);
        } else if (res.name == "Corporate Discount") {
          res.percent = this.corpDiscount ? this.corpDiscount : 0;
          const percentage = parseFloat(res.percent);
          const discount = this.convertIntoTwoDecimal(
            (subTotal * percentage) / 100
          );
          res.value = discount;
          discounts = res.value;
        } else {
          const percentage = parseFloat(res.percent);
          totalTaxPercentage.push(percentage);
          // to do need to fix calculation
          const actualAmount = subTotal - discounts;
          const tax = this.convertIntoTwoDecimal(
            (actualAmount * percentage) / 100
          );
          res.value = tax;
        }
      });

      this.totalAmount = 0;
      this.totalTaxes = totalTaxPercentage.map((a)=>a);

      const taxSum = this.totalTaxes.reduce((a,b)=>a+b,0)
      const corpDiscount = this.corpDiscount ? this.corpDiscount : 0;
      const discountedPrice = this.subTotal * (corpDiscount/100);
      const taxAmount = (this.subTotal - discountedPrice) * (taxSum/100);
      const total_amount = (this.subTotal - discountedPrice + taxAmount);

      this.totalAmount = this.convertIntoTwoDecimal(total_amount);

      // this.subTotalFields.forEach((res) => {
      //   // Subtract the corporate discount
      //   if (res.name === "Corporate Discount") {
      //     this.totalAmount = this.totalAmount - +res.value;
      //   }
      //   // add all others value
      //   else {
      //     this.totalAmount += +res.value;
      //   }
      // });

      // this.totalAmount = this.convertIntoTwoDecimal(this.totalAmount);
    },

    convertIntoTwoDecimal(value) {
      return Number(value).toFixed(this.currencyDecimalPlace || 2);
    },
  },
};
</script>

<style scoped lang="scss">
.removeArrowKey {
  -moz-appearance: textfield;
}
.removeArrowKey::-webkit-outer-spin-button,
.removeArrowKey::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
::v-deep {
  .disableInput {
    input {
      background: #f9fafb !important;
      cursor: not-allowed;
    }
  }
  .disableDropdown {
    background: #f9fafb !important;
    cursor: not-allowed;
  }
}
</style>
