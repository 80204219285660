import axios from "@/axios";

export async function getOrdersList() {
  const url = "/client-purchase-order";
  return await axios.get(url);
}

export async function getClientTax(clientId) {
  const url = `/tax/tax-client/${clientId}`;
  return await axios.get(url);
}

export async function createOrder(payload) {
  const url = `/client-purchase-order`
  return await axios.post(url, payload);
}

export async function getClientConfig(clientId) {
  const url = `/client/${clientId}`;
  return await axios.get(url);
}

export async function getOrderId() {
  const url = `/client-purchase-order/get-order-number`;
  return await axios.get(url);
}

export async function getOrderDetails(orderId) {
  const url = `/client-purchase-order/${orderId}`;
  return await axios.get(url);
}

export async function getClientCreditPackList() {
  const url = `/client-purchase-order/get-credit-packs`;
  return await axios.get(url);
}

export async function getOrderCreditPacks(orderId) {
  const url = `/client-purchase-order/${orderId}/credit-packs`;
  return await axios.get(url);
}

export async function updateOrder(payload, orderId) {
  const url = `/client-purchase-order/${orderId}`;
  return await axios.put(url, payload);
}
